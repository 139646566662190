import React, { Component } from "react";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

import "./Skills.css";

const GET_SKILLS = gql`
  query getSkills {
    skills {
      id
      name
      icon
    }
  }
`;

class Skills extends Component {
  componentDidUpdate() {
    // this.applyMovement();
  }

  applyMovement = () => {
    this.container = document.getElementsByClassName("skills");
    this.logos = this.container[0] ? this.container[0].children : [];

    for (let i = 0; i < this.logos.length; i++) {
      this.logos[i].style.setProperty("animation-delay", Math.random() + "s");
      this.logos[i].style.setProperty(
        "-moz-animation-delay",
        Math.random() + "s"
      );
    }
  };

  skillsList = (skills = []) => {
    if (!skills.length) return null;

    return (
      <div className="skills">
        {skills.map((skill, index) => (
          <div key={index}>
            <div className="logo-container">
              <span
                className="logo"
                style={{ backgroundImage: `url('${skill.icon}')` }}
              />
            </div>
            <b>{skill.name}</b>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Query query={GET_SKILLS}>
          {({ data, loading, error }) => {
            if (loading) return <div>Cargando</div>;
            if (error) return <p>No Skills!</p>;

            if (data.skills) {
              setTimeout(this.applyMovement, 1000);
              return this.skillsList(data.skills);
            }
          }}
        </Query>
      </div>
    );
  }
}

export default Skills;
